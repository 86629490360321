import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import "react-quill/dist/quill.bubble.css"; // Import Quill styles

const QuillEditor = ({ form, fieldName, onChange, theme, readOnly, height, tvalue, showToolbar , toolbarOptions }) => {
  
  
  const [editorHtml, setEditorHtml] = useState(tvalue || ""); 

  useEffect(() => {
    setEditorHtml(tvalue || ""); 
    if (fieldName && form) { 
      setEditorHtml(form.getFieldValue(fieldName) || "");
    }
  }, [form, fieldName,tvalue]);

 

  const handleChange = (html) => {
    setEditorHtml(html); // Update local editor state
    if (fieldName && form) {
      form.setFieldsValue({ [fieldName]: html }); // Update form field
    }
    if (onChange) {
      onChange(html); // Call parent onChange if provided
    }
  };

  return (
    <div>
      <style jsx>{`
        /* Custom inline styles for Quill toolbar */
        .ql-toolbar .ql-formats button,
        .ql-toolbar .ql-formats .ql-picker-label,
        .ql-toolbar .ql-formats .ql-picker-item {
          font-size: 14px !important;
        }
        .ql-toolbar button svg {
          width: 16px;
          height: 16px;
        }
      `}</style>
      <ReactQuill
        theme={theme}
        onChange={handleChange} // Handle content change and update Formik
        modules={{ toolbar: toolbarOptions || (showToolbar ? QuillEditor.modules.toolbar : false) }}
        formats={QuillEditor.formats} // Quill formats (allowed formatting)
        style={{ height: height }}
        value={editorHtml}
        readOnly={readOnly}
      />
    </div>
  );
};

// Quill editor modules (toolbar setup)
QuillEditor.modules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    [{ font: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    ["link", "image"],
  ],
  clipboard: {
    matchVisual: true, // Enable matching visual clipboard to handle lists properly
  },
};

// Quill editor formats (allowed formats)
QuillEditor.formats = [
  "header", "font",
  "bold", "italic", "underline", "strike", "blockquote",
  "list", "bullet", "indent",
  "link", "image"
];

// PropType validation (ensures required props are passed)
QuillEditor.propTypes = {
  form: PropTypes.object.isRequired, // Ensure `form` is passed from Formik
  fieldName: PropTypes.string.isRequired, // Field name to map in Formik
  placeholder: PropTypes.string, // Optional placeholder text
  showToolbar: PropTypes.bool, // New prop to control toolbar visibility
};

QuillEditor.defaultProps = {
  showToolbar: true, // Set default to show the toolbar
};

export default QuillEditor;
