import { lazy } from "react";
import ViewInteraction from "../../pages/Interactions/ViewInteractions";

const Customer = lazy(() => import("../../pages/Customer"));
//const Tenant = lazy(() => import("../../pages/Tenant"));
const TenantNew = lazy(() => import("../../pages/TenantNew"));
const CreateCustomer = lazy(() => import("../../pages/CreateCustomer"));
// const CustomerTenantMapping = lazy(() =>
//   import("../../pages/CustomerTenantMapping")
// );
const CustomerHirarchy = lazy(() => import("../../pages/CustomerHirarchy"));
const EditCustomer = lazy(() => import("../../pages/EditCustomer"));
const ViewCustomer = lazy(() => import("../../pages/ViewCustomer"));
const ViewCustomer360 = lazy(() => import("../../pages/ViewCustomer360"));
const CustomerRecordAudit = lazy(() =>
  import("../../pages/CustomerAuditLogs/CustomerRecordAudit")
);
const TenantRecordAudit = lazy(() => import("../../pages/TenantRecordAudit"));
const TenantMappingdAudit = lazy(() =>
  import("../../pages/CustomerAuditLogs/TenantMappingAudit")
);
const TenantMappingdAuditAction = lazy(() =>
  import("../../pages/TenantMappingAuditAction")
);
const CustomerHirarchydAudit = lazy(() =>
  import("../../pages/CustomerAuditLogs/CustomerHirarchyAudit")
);
const BusinessTags = lazy(() => import("../../pages/Tags/BusinessTags"));
const MyTags = lazy(() => import("../../pages/Tags/MyTags"));
const ClusteringTags = lazy(() => import("../../pages/Tags/ClusteringTags"));

const DataMasteringTags = lazy(() =>
  import("../../pages/Tags/DataMasteringTags")
);

const ClientPartners = lazy(() => import("../../pages/ClientPartner"));
const CustomerCart = lazy(() => import("../../pages/Cart"));
const TenantCart = lazy(() => import("../../pages/Cart2"));
const MergeCustomer = lazy(() => import("../../pages/MergeCustomer"));
const Health = lazy(() => import("../../pages/Health"));
const Clustering = lazy(() => import("../../pages/Clustering"));
const BatchResults = lazy(() => import("../../pages/Batch Results"));
const BatchResults2 = lazy(() => import("../../pages/Batch Results2"));

const MapCustomer = lazy(() => import("../../pages/Map Customer to Tenant"));

const ViewTenant = lazy(() => import("../../pages/ViewTenant"));
const EditTenant = lazy(() => import("../../pages/EditTenant"));

/* audit pages */
const CustomerDBAuditLogs = lazy(() =>
  import("../../pages/CustomerDBAuditLogs")
);
const CustomerMergeAuditLogs = lazy(() =>
  import("../../pages/CustomerMergeAuditLogs")
);
const ChildCustomerAudit = lazy(() => import("../../pages/ChildCustomerAudit"));
const TenantDBAuditLogs = lazy(() => import("../../pages/TenantDBAuditLogs"));
const MappingAudit = lazy(() => import("../../pages/MappingAuditLogs"));

const UpdateMdm = lazy(() => import("../../pages/Update MDM/"));



const CustomerInteractions = lazy(() =>
  import("../../pages/Interactions/CustomerInteractions")
);
const CustomerActions = lazy(() => import("../../pages/Customer Action Items"));


const CreateInteractions = lazy(() =>
  import("../../pages/Interactions/CreateInteractions")
);

const ViewInteractions = lazy(() =>
  import("../../pages/Interactions/ViewInteractions")
);

const EditInteractions = lazy(() =>
  import("../../pages/Interactions/EditInteractions")
);


const ConfigRoutes = {
  pages: [
    {
      name: "Customer",
      page: Customer,
      path: ["/customers", "/"],
      props: {
        exact: true,
      },
    },
    {
      name: "Tenant",
      page: TenantNew,
      path: ["/tenants"],
      props: {
        exact: true,
      },
    },

    {
      name: "CreateCustomer",
      page: CreateCustomer,
      path: ["/create-customer"],
      props: {
        exact: true,
      },
    },
    {
      name: "EditCustomer",
      page: EditCustomer,
      path: ["/edit-customer/:id"],
      props: {
        exact: true,
      },
    },
    {
      name: "ViewCustomer",
      page: ViewCustomer,
      path: ["/view-customer/:id"],
      props: {
        exact: true,
      },
    },
    {
      name: "ViewCustomer360",
      page: ViewCustomer360,
      path: ["/view-customer360/:id"],
      props: {
        exact: true,
      },
    },
    // {
    //   name: "CustomerTenantMapping",
    //   page: CustomerTenantMapping,
    //   path: ["/customer-tenant-mapping/:id"],
    //   props: {
    //     exact: true,
    //   },
    // },
    {
      name: "TenantNew",
      page: TenantNew,
      path: ["/customer-tenant-mapping/:cid"],
      props: {
        exact: true,
      },
    },
    {
      name: "CustomerRecordAudit",
      page: CustomerRecordAudit,
      path: ["/customer-record-audit/:id"],
      props: {
        exact: true,
      },
    },
    {
      name: "TenantMappingdAudit",
      page: TenantMappingdAudit,
      path: ["/tenant-mapping-audit/:id"],
      props: {
        exact: true,
      },
    },
    {
      name: "TenantMappingdAuditAction",
      page: TenantMappingdAuditAction,
      path: ["/tenant-mapping-audit-action/:id"],
      props: {
        exact: true,
      },
    },
    {
      name: "CustomerHirarchydAudit",
      page: CustomerHirarchydAudit,
      path: ["/customer-hierarchy-audit/:id"],
      props: {
        exact: true,
      },
    },
    {
      name: "CustomerHirarchy",
      page: CustomerHirarchy,
      path: ["/customer-hierarchy/:id"],
      props: {
        exact: true,
      },
    },
    {
      name: "BusinessTags",
      page: BusinessTags,
      path: ["/business-tags"],
      props: {
        exact: true,
      },
    },
    {
      name: "DataMasteringTags",
      page: DataMasteringTags,
      path: ["/datamastering-tags"],
      props: {
        exact: true,
      },
    },
    {
      name: "MyTags",
      page: MyTags,
      path: ["/my-tags"],
      props: {
        exact: true,
      },
    },
    {
      name: "ClusteringTags",
      page: ClusteringTags,
      path: ["/clustering-tags"],
      props: {
        exact: true,
      },
    },
    {
      name: "ClientPartners",
      page: ClientPartners,
      path: ["/client-partners"],
      props: {
        exact: true,
      },
    },
    {
      name: "CustomerCart",
      page: CustomerCart,
      path: ["/customer-cart"],
      props: {
        exact: true,
      },
    },
    {
      name: "TenantCart",
      page: TenantCart,
      path: ["/tenant-cart"],
      props: {
        exact: true,
      },
    },
    {
      name: "MergeCustomer",
      page: MergeCustomer,
      path: ["/merge-customers/:child/:parent"],
      props: {
        exact: true,
      },
    },
    {
      name: "Health",
      page: Health,
      path: ["/health"],
      props: {
        exact: true,
      },
    },
    {
      name: "ViewTenant",
      page: ViewTenant,
      path: ["/view-tenant/:id"],
      props: {
        exact: true,
      },
    },
    {
      name: "EditTenant",
      page: EditTenant,
      path: ["/edit-tenant/:id"],
      props: {
        exact: true,
      },
    },
    {
      name: "tenantRecordAudit",
      page: TenantRecordAudit,
      path: ["/tenant-record-audit/:id"],
      props: {
        exact: true,
      },
    },
    {
      name: "CustomerDatabaseAuditLogs",
      page: CustomerDBAuditLogs,
      path: ["/customer-database-audit"],
      props: {
        exact: true,
      },
    },
    {
      name: "CustomerMergeAuditLogs",
      page: CustomerMergeAuditLogs,
      path: ["/customer-merge-audit"],
      props: {
        exact: true,
      },
    },
    {
      name: "ChildCustomerAudit",
      page: ChildCustomerAudit,
      path: ["/child-customer-audit"],
      props: {
        exact: true,
      },
    },
    {
      name: "TenantDatabaseAuditLogs",
      page: TenantDBAuditLogs,
      path: ["/tenant-database-audit"],
      props: {
        exact: true,
      },
    },
    {
      name: "MappingAudit",
      page: MappingAudit,
      path: ["/mapping-audit"],
      props: {
        exact: true,
      },
    },
    {
      name: "Clustering",
      page: Clustering,
      path: ["/clustering"],
      props: {
        exact: true,
      },
    },
    {
      name: "Batch Results",
      page: BatchResults,
      path: ["/clustering/results/:id"],
      props: {
        exact: true,
      },
    },
    {
      name: "Batch Results",
      page: BatchResults,
      path: ["/clustering/results/:id/search/:searchid"],
      props: {
        exact: true,
      },
    },
    {
      name: "Map Customers",
      page: MapCustomer,
      path: ["/clustering/results/:id/mapCustomer"],
      props: {
        exact: true,
      },
    },
    {
      name: "Batch Results",
      page: BatchResults2,
      path: ["/clustering/results/:id/tenant/:tenant_id"],
      props: {
        exact: true,
      },
    },
    {
      name: "Update MDM",
      page: UpdateMdm,
      path: ["/clustering/updatemdm/:batch_id"],
      props: {
        exact: true,
      },
    },
    {
      name: "Customer Interactions",
      page: CustomerInteractions,
      path: ["/customer-interactions"],
      props: {
        exact: true,
      },
      },
      {
        name: "Customer Interactions",
        page: CustomerInteractions,
        path: ["/customer-interactions/:cid(\\d+)"],
        props: {
          exact: true,
        },
      },
      
    {
      name: "Create Interactions",
      page: CreateInteractions,
      path: ["/customer-interactions/create-interactions/"],
      props: {
        exact: true,
      }
    },
    {
      name: "View Interactions",
      page: ViewInteractions,
      path: ["/customer-interactions/view-interactions/:id"],
      props: {
        exact: true,
      }
    },
    {
      name: "Edit Interactions",
      page: EditInteractions,
      path: ["/customer-interactions/edit-interactions/:id"],
      props: {
        exact: true,
      }
    },
    {
      name: "Customer Actions",
      page: CustomerActions,
      path: ["/customer-action-items"],
      props: {
        exact: true,
      },
    },
    {
      name: "Customer Actions",
      page: CustomerActions,
      path: ["/customer-action-items"],
      props: {
        exact: true,
      },
    },
    {
      name: "Customer Actions",
      page: CustomerActions,
      path: ["/customer-action-items/:cid(\\d+)"],
      props: {
        exact: true,
      },
    },
  ],
};

export default ConfigRoutes;
