import React, { useContext, lazy, useEffect } from "react";
import { Route, Router, Switch } from "react-router-dom";
import { CustomerProvider } from "../../store/CustomerProvider";
import { Customer360Provider } from "../../store/Customer360Provider";

/* Antd */
import "antd/dist/antd.css";

/* Global Component */
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";

/*ConfigRoutes */
import ConfigRoutes from "../../config/Routing";

/* Utils */
import { History } from "../../utils";

import { Layout } from "antd";

/*  Context Provider */
import AuthContext from "../../state/authContext";

/* Pages */
const PageNotFound = lazy(() => import("../../pages/PageNotFound"));
const Login = lazy(() => import("../../pages/LoginPage"));
const PublicPageCustomer = lazy(() => import("../../pages/PublicPageCustomer"));

const { Content } = Layout;

const LayoutView = () => {
  useEffect(setMeta, []);
  const authCntx = useContext(AuthContext);
  const isLoggedIn = authCntx.isLoggedIn;
  const currentURL = window.location.href;

  if (!currentURL.includes("view-interactions")) {
    localStorage.removeItem("collapsable");
  }
  const LazyLoadPage = (props) => {
    return <React.Suspense fallback={<></>}>{props.children}</React.Suspense>;
  };

  return (
    <Router history={History}>
      <Switch>
        {/* Public route - doesn't require authentication */}
        <Route path="/view-customer-page">
          <LazyLoadPage>
            <PublicPageCustomer />
          </LazyLoadPage>
        </Route>

        {isLoggedIn ? (
          ConfigRoutes.pages.map(({ name, page: Page, path, props }) => {
            return (
              <Route {...props} key={name} path={path}>
                <Layout>
                  <Navbar />
                  <Layout>
                    <Sidebar />
                    <Content
                      style={{
                        marginLeft: "50px",
                        marginRight: "2px",
                        minHeight: "100vh",
                        minWidth: "90vw",
                      }}
                    >
                      <LazyLoadPage>
                        <CustomerProvider>
                          <Customer360Provider>
                            <Page />
                          </Customer360Provider>
                        </CustomerProvider>
                      </LazyLoadPage>
                    </Content>
                  </Layout>
                </Layout>
              </Route>
            );
          })
        ) : (
          <Route>
            <LazyLoadPage>
              <Login />
            </LazyLoadPage>
          </Route>
        )}
        <Route>
          <Layout>
            <Navbar />
            <Layout>
              <Sidebar />
              <Content
                style={{
                  marginLeft: "50px",
                  marginRight: "2px",
                  minHeight: "100vh",
                  minWidth: "90vw",
                }}
              >
                <LazyLoadPage>
                  <PageNotFound />
                </LazyLoadPage>
              </Content>
            </Layout>
          </Layout>
        </Route>
      </Switch>
    </Router>
  );
};

const setMeta = () => {
  let faviconLink =
    document.querySelector("link[rel*='icon']") ||
    document.createElement("link");
  faviconLink.type = "image/x-icon";
  faviconLink.rel = "icon";

  if (process.env.REACT_APP_ENV === "production") {
    faviconLink.href = "/favicon-prod.png";
  } else if (process.env.REACT_APP_ENV === "staging") {
    faviconLink.href = "/favicon-staging.png";
  } else {
    faviconLink.href = "/favicon-testing.png";
  }
  document.getElementsByTagName("head")[0].appendChild(faviconLink);
};

export default LayoutView;
